import React, { useContext } from 'react';
import { PageMetadataContext } from '../../../templates/PageMetaDataContext';
import {
    countriesList,
    defaultDomainCountryCodes,
} from '../../../global/countries';
//@ts-ignore
import * as styles from './CtaLink.module.css';

interface Props {
    href?: string;
    target?: string;
    color?: string;
    hoverBorderColor?: string;
    hoverFontColor?: string;
    hoverBgColor?: string;
    visualStyle?: 'outlineDark' | 'outlineLight';
    id?: string | undefined;
}

const CtaLink: React.FC<Props> = ({
    href,
    target,
    color,
    hoverBorderColor,
    hoverFontColor,
    hoverBgColor,
    visualStyle = 'outlineDark',
    children,
    id = undefined,
}) => {
    const PageMetadata = useContext(PageMetadataContext);
    const preferredLanguage =
        useContext(PageMetadataContext).preferredLanguage?.toLowerCase();

    const inlineStyle: React.CSSProperties = {};
    const hoverBorderColorHex = hoverBorderColor || '#f06623';
    const hoverFontColorHex = hoverFontColor || '#ffffff';
    const hoverBgColorHex = hoverBgColor || '#f06623';

    if (color) {
        inlineStyle.color = color;
        inlineStyle.borderColor = color;
    }

    let languageSpecificHref =
        !preferredLanguage ||
        defaultDomainCountryCodes.indexOf(preferredLanguage.toLowerCase()) >
            -1 ||
        href?.includes(preferredLanguage) ||
        href?.includes('//')
            ? href
            : `/${preferredLanguage}${href}`;

    if (languageSpecificHref !== undefined) {
        const [firstCountryValue, secondCountryValue] = languageSpecificHref
            .split('/')
            .filter((item) => item);
        languageSpecificHref =
            countriesList.indexOf(firstCountryValue) > -1 &&
            countriesList.indexOf(secondCountryValue) > -1
                ? href
                : languageSpecificHref;
    }

    const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        if (languageSpecificHref && languageSpecificHref.includes('#')) {
            if (languageSpecificHref!) e.preventDefault();

            // Extract the path and hash from the href
            const [path, hash] = languageSpecificHref.split('#');
            const elementId = hash;

            // Scroll to the element if it exists
            if (elementId) {
                const element = document.getElementById(elementId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }

            // Preserve the query parameters and update the URL path and hash
            const url = new URL(window.location.href);
            if (path && !path.startsWith('http')) {
                url.pathname = path;
            }
            url.hash = elementId || '';
            if (path.includes(window?.location?.hostname)) {
                if (path && url.pathname !== window.location.pathname) {
                    window.location.href = url.toString();
                    // window.history.pushState(null, null, url.toString());
                } else {
                    window.history.pushState(null, null, url.toString());
                }
            } else {
                window.location.href =
                    path + (elementId ? `#${elementId}` : '');
            }
        }
    };

    return (
        <>
            {color && (
                <style>{`.${styles.ctaLink}:hover { 
                    border-color: ${hoverBorderColorHex} !important; 
                    color: ${hoverFontColorHex} !important;
                    background-color: ${hoverBgColorHex} !important;
                }`}</style>
            )}
            <a
                id={id ? id : undefined}
                href={languageSpecificHref}
                target={target}
                className={`${styles.ctaLink} ${styles[visualStyle]}`}
                style={inlineStyle}
                // onClick={handleClick}
            >
                {children}
            </a>
        </>
    );
};

export default CtaLink;
